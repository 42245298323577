@import "../../../../../global-style.scss";

#livestream-profile-view-dialog-user-info-subcomponent {
    background-color: white;

    .padding-container {
        padding: 30px 20px;

        .user-info-container {
            display: flex;

            .profile-picture-container {
                width: 70px;
                position: relative;

                .profile-picture {
                    height: 60px;
                    width: 60px;
                }

                .levelling-badge-container {
                    position: absolute;
                    top: 48px;
                    left: 12px;

                    .levelling-badge {
                        height: 13px;
                    }
                }
            }

            .user-details-container {
                height: 100px;
                width: calc(100% - 70px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .top-container {
                    padding-left: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .username-container {
                        display: flex;

                        .achievement-container {
                            padding-right: 3px;
                            display: flex;
                            align-items: center;
                            gap: 3px;

                            .livestreamer-badge {
                                height: 13px;
                            }
                        }

                        .username {
                            font: 600 1.1em Lato;
                        }
                    }

                    .follow-button {
                        padding: 6px 20px;
                        border: 1px solid $sugarbook-main-color;
                        border-radius: 25px;
                        text-align: center;
                        cursor: pointer;
                        font: 400 0.8em Lato;
                        color: $sugarbook-main-color;
                    }

                    .unfollow-button {
                        padding: 6px 20px;
                        border: 1px solid $sugarbook-main-color;
                        border-radius: 25px;
                        text-align: center;
                        cursor: pointer;
                        font: 400 0.8em Lato;
                        background-color: $sugarbook-main-color;
                        color: white;
                    }
                }

                .user-stats-container {
                    height: 50px;
                    display: flex;

                    .follower-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;

                        .followers-count {
                            font: 600 1.3em Lato;
                            color: $sugarbook-main-color;
                        }

                        .followers-label {
                            font: 400 0.9em Lato;
                        }
                    }

                    .viewer-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;

                        .viewers-count {
                            font: 600 1.3em Lato;
                            color: $sugarbook-main-color;
                        }

                        .viewers-label {
                            font: 400 0.9em Lato;
                        }
                    }

                    .diamonds-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;

                        .diamonds-count {
                            font: 600 1.3em Lato;
                        }

                        .diamonds-label {
                            font: 400 0.9em Lato;
                        }
                    }

                    .vertical-divider {
                        height: 70%;
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }
            }
        }
    }
}

// Responsive Design
@media (min-width: 900px) {
    #livestream-profile-view-dialog-user-info-subcomponent {

        .padding-container {
            padding: 10px 15px 0 15px;

            .user-info-container {

                .user-details-container {
                    justify-content: unset;
                    gap: 10px;
                }
            }
        }
    }
}