@import "../../../global-style.scss";

#alphapo-payment-redirect-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .max-width-container {
        width: 100%;
        max-width: 500px;
        padding: 25px;

        .padding-container {
            padding: 20px 0;
        }

        .payment-success {
            height: calc(100% - 130px);
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 35px;
            overflow: auto;

            .xenopay-title-container {
                display: flex;
                flex-direction: column;
                gap: 7px;

                .payment-status-label {
                    text-align: center;
                    text-transform: capitalize;
                    font: 800 1.8em Lato;
                    color: #333333;
                }

                .thank-you-label {
                    text-align: center;
                    font: 500 1em Lato;
                    color: #7c7c7c;
                }
            }

            .xenopay-body-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .your-purchase-label {
                    text-align: center;
                    font: 500 1em Lato;
                    color: #7c7c7c;
                }

                .coin-package-container {
                    padding: 10px;
                    display: flex;
                    border-radius: 8px;
                    background-color: #f2f2f2;

                    .icon-container {
                        width: 80px;

                        .coin-icon-container {
                            height: 50px;
                            width: 50px;
                            padding: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            background-color: white;

                            .coin-icon {
                                height: 40px;
                            }
                        }
                    }

                    .premium-description {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .months-premium-label {
                            font: 600 1em Lato;
                        }

                        .total-billed {
                            font: 500 0.9em Lato;
                            color: #7c7c7c;
                        }
                    }
                }

                .transaction-reference {
                    text-align: center;
                    font: 600 1em Lato;
                    color: #333333;
                }
            }

            .discrete-note {
                padding-top: 20%;
                text-align: center;
                font: 400 1em Lato;
                color: #7c7c7c;
            }

            .discretion-container {
                padding-top: 20%;
                text-align: center;
                font: 400 1em Lato;

                .discretion-head {
                    color: #7C7C7C;
                }

                .discretion-body {}
            }
        }

        .payment-error {
            height: calc(100% - 130px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            gap: 35px;
            overflow: auto;

            .payment-error-icon-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .payment-error-icon {
                    height: 60px;
                }
            }

            .payment-error-title {
                text-align: center;
                text-transform: capitalize;
                font: 800 1.8em Lato;
                color: #FF0000;
            }

            .payment-error-description {
                text-align: center;
                font: 500 1em Lato;
                color: #7C7C7C;
            }
        }

        .bottom-container {
            height: 80px;
            padding-top: 10px;

            .continue-button {
                height: 47px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .try-again-button {
                height: 47px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }
    }

    // Utility CSS
    .payment-error-background {
        background-color: #FFDDDD;
    }
}