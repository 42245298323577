#livestream-profile-view-dialog-viewer-info-subcomponent {
    overflow-y: auto;
    background-color: white;

    .tab-container {
        height: 100%;

        .embla {
            height: calc(100% - 48px);
            width: 100%;
            overflow-x: hidden;

            .embla__container {
                height: 100%;
                display: flex;

                .embla__slide {
                    flex: 0 0 100%;
                    min-width: 0;
                }
            }
        }
    }
}

// Utility CSS
.screen-height {
    height: 55vh;
    // min-height: 500px;
    // max-height: 600px;
}

.fix-height {
    height: calc(100% - 161px - 42px);
}

// Responsive Design
@media (min-width: 900px) {
    .fix-height {
        height: calc(100% - 151px - 2px);
    }
}