@import "../../../../../../global-style.scss";

#viewer-info-live-tab-subcomponent {
    height: 100%;

    .padding-container {
        height: calc(100% - 40px);
        padding: 20px;
        overflow: auto;
    }
}

// Responsive Design
@media (min-width: 900px) {
    #viewer-info-live-tab-subcomponent {

        .padding-container {
            height: calc(100% - 5px);
            padding: 5px 20px 0 20px;
        }
    }
}