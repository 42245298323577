@import "../../../../global-style.scss";

#mobile-profile-edit-photos-subcomponent {

    .edit-photos-padding-container {
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .public-photos-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .public-photos-label {
                padding-left: 20px;
                font: 600 1.5em Lato;
            }

            .dnd-container {
                position: relative;
            }

            .public-photo-stack-container {
                width: 100%;
                display: flex;
                flex-direction: column;

                .top-stack {
                    width: 100%;
                    display: flex;

                    .profile-photo-container {
                        flex: 2;

                        .profile-photo {
                            margin: 10px;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 15px;
                            aspect-ratio: 1 / 1;
                            background: #EFEFEF;

                            .profile-photo-img {
                                height: 100%;
                                width: 100%;
                                border-radius: 15px;
                                object-fit: cover;
                            }

                            .add-photo-icon {
                                font-size: 100px;
                                color: #DDDDDD;
                            }
                        }
                    }

                    .side-image-container {
                        display: flex;
                        // display: grid;
                        flex-direction: column;
                        flex: 1;

                        .side-image {
                            height: calc(100% - 20px);

                            margin: 10px;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 15px;
                            // aspect-ratio: 1 / 1;
                            background: #EFEFEF;

                            .public-photo-img {
                                height: 100%;
                                width: 100%;
                                border-radius: 15px;
                                aspect-ratio: 1 / 1;
                                object-fit: cover;
                            }

                            .add-photo-icon {
                                font-size: 60px;
                                color: #DDDDDD;
                            }
                        }
                    }
                }

                .bottom-stack {
                    width: 100%;
                    display: flex;

                    .bottom-left-image-container {
                        display: flex;
                        flex: 2;

                        .bottom-image {
                            margin: 10px;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex: 1;
                            border-radius: 15px;
                            aspect-ratio: 1 / 1;
                            background: #EFEFEF;

                            .public-photo-img {
                                height: 100%;
                                width: 100%;
                                border-radius: 15px;
                                object-fit: cover;
                            }

                            .add-photo-icon {
                                font-size: 60px;
                                color: #DDDDDD;
                            }
                        }
                    }

                    .bottom-right-image-container {
                        // display: flex; // might need to remove
                        flex: 1;

                        .bottom-image {
                            margin: 10px;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 15px;
                            aspect-ratio: 1 / 1;
                            background: #EFEFEF;

                            .public-photo-img {
                                height: 100%;
                                width: 100%;
                                border-radius: 15px;
                                object-fit: cover;
                            }

                            .upload-photo-container {
                                background: #EFEFEF;

                                .add-photo-icon {
                                    font-size: 60px;
                                    color: #DDDDDD;
                                }
                            }
                        }
                    }
                }
            }

            .public-photo-note {
                text-align: center;
                font: 500 0.9em Lato;
                color: #979797;

                .info-icon {
                    position: relative;
                    top: 2px;
                    right: 5px;
                    font-size: 15px;
                }
            }
        }

        .private-photos-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .private-photos-label {
                padding-left: 20px;
                display: flex;
                align-items: center;
                gap: 10px;
                font: 600 1.5em Lato;

                .lock-icon {
                    color: #d0cccc;
                }
            }

            .private-photo-body-container {
                width: calc(100% - 20px);
                padding: 0 10px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                .private-photo {
                    // margin: 10px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
                    aspect-ratio: 1 / 1;
                    background: #EFEFEF;

                    .private-photo-image {
                        height: 100%;
                        width: 100%;
                        border-radius: 15px;
                        object-fit: cover;
                    }

                    .add-photo-icon {
                        font-size: 60px;
                        color: #DDDDDD;
                    }
                }
            }

            .private-photo-note {
                text-align: center;
                font: 500 0.9em Lato;
                color: #979797;
            }

            .photos-container {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 10px;

                .photo-container {
                    height: 100px;
                    width: 100px;
                    position: relative;

                    .private-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                }

                .add-photo-container {
                    height: 100px;
                    width: 100px;
                    padding: 0;

                    .add-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                    }
                }
            }
        }

        // Utility CSS
        .photo-status-container {
            height: 20%;
            width: 100%;
            max-height: 30px;
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 0 15px 15px;
            font: 600 0.8em Lato;
            color: white;
        }

        .in-review-background {
            background: black;
        }

        .red-background {
            background: red;
        }

        .in-review-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            text-transform: capitalize;
            font: 600 0.7em Lato;
            background-color: rgba(0, 0, 0, 0.53);
            color: white;
        }

        .rejected-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;
            text-transform: capitalize;
            font: 600 0.7em Lato;
            background-color: rgba(0, 0, 0, 0.53);
            color: white;

            .rejected-label {
                width: 100%;
                padding: 5px 0;
                border-radius: 0 0 10px 10px;
                text-align: center;
                font: 600 1.1em Lato;
                background-color: $sugarbook-main-color;
            }
        }
    }
}

#desktop-profile-edit-photos-subcomponent {

    .padding-container {
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 50px;

        .public-photos-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .public-photos-top-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .public-photos-label-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .public-photos-label {
                        font: 600 1.2em Lato;
                    }

                    .public-photos-amount {
                        color: #999999;
                    }
                }

                .add-photos-button-container {
                    padding: 7px 20px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    border: 2px solid $sugarbook-main-color;
                    border-radius: 25px;

                    .add-photo-icon-container {
                        display: flex;
                        align-items: center;

                        .add-photo-icon {
                            font-size: 20px;
                            color: $sugarbook-main-color;
                        }
                    }

                    .add-photo-label {
                        text-transform: capitalize;
                        font: 600 1em Lato;
                        color: $sugarbook-main-text-color;
                    }
                }
            }

            .photos-container {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 10px;


                .photo-container {
                    height: 150px;
                    width: 150px;
                    position: relative;
                    cursor: pointer;

                    .public-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                }

                .add-photo-container {
                    height: 150px;
                    width: 150px;
                    padding: 0;

                    .add-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                    }
                }
            }
        }

        .private-photos-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .private-photos-top-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .private-photos-label-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .private-photos-label {
                        font: 600 1.2em Lato;
                    }

                    .private-photos-amount {
                        color: #999999;
                    }
                }

                .add-photos-button-container {
                    padding: 7px 20px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    border: 2px solid $sugarbook-main-color;
                    border-radius: 25px;

                    .add-photo-icon-container {
                        display: flex;
                        align-items: center;

                        .add-photo-icon {
                            font-size: 20px;
                            color: $sugarbook-main-color;
                        }
                    }

                    .add-photo-label {
                        text-transform: capitalize;
                        font: 600 1em Lato;
                        color: $sugarbook-main-text-color;
                    }
                }
            }

            .photos-container {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 10px;

                .photo-container {
                    height: 150px;
                    width: 150px;
                    position: relative;
                    cursor: pointer;

                    .private-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                }

                .add-photo-container {
                    height: 150px;
                    width: 150px;
                    padding: 0;

                    .add-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 10px;
                    }
                }
            }
        }

        .upload-photo-container {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border-radius: 10px;
            background-color: rgb(250, 250, 250);

            .upload-icon-container {
                display: flex;
                align-items: center;

                .upload-icon {}
            }

            .upload-photo-label {
                font: 600 1em Lato;
                color: grey;
            }
        }

        // Utility CSS
        .short-divider {
            width: 30px;
        }

        .in-review-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            text-transform: capitalize;
            font: 600 0.9em Lato;
            background-color: rgba(0, 0, 0, 0.53);
            color: white;
        }

        .rejected-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            border-radius: 10px;
            text-transform: capitalize;
            background-color: rgba(0, 0, 0, 0.53);
            color: white;

            .rejected-label {
                width: 100%;
                padding: 10px 0;
                border-radius: 0 0 10px 10px;
                text-align: center;
                font: 600 0.9em Lato;
                background-color: $sugarbook-main-color;
            }
        }
    }
}