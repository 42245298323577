@import "../../../global-style.scss";

#coins-payment-confirmed-element-subcomponent {
    height: 65px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .payment-icon-container {
        width: 55px;
        position: relative;

        .payment-icon {
            height: 40px;
        }

        .payment-confirmed-icon {
            width: 18px;
            position: absolute;
            bottom: 2px;
            right: 10px;
        }
    }

    .coins-payment-body-container {
        width: calc(100% - 55px);

        .timestamp {
            color: #979797;
        }
    }
}