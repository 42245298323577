#private-call-suspend-dialog {
    height: 100%;
    position: relative;
    overflow: auto;

    .background-image-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        background: grey;

        .background-image {
            height: 100%;
            width: 100%;
            display: block;
            object-fit: cover;
            filter: blur(15px) brightness(50%);
        }
    }

    .private-call-suspend-content-container {
        height: 100%;
        position: relative;
        z-index: 2;

        .close-button-container {
            padding: 15px;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            .close-button {
                color: white;
            }
        }

        .padding-container {
            padding: 120px 30px 60px 30px;

            .caution-icon-container {
                display: flex;
                justify-content: center;

                .caution-icon {
                    font-size: 75px;
                    color: white;
                }
            }

            .private-call-suspended-title {
                text-align: center;
                font: bold 1.5em Lato;
                color: white;
            }

            .private-call-suspended-description {
                text-align: center;
                font: normal 1em Lato;
                color: white;
            }

            .private-call-suspended-description-2 {
                text-align: center;
                font: normal 1em Lato;
                color: white;
            }

            .private-call-suspended-countdown {
                text-align: center;
                font: bold 1em Lato;
                color: #f3c64a;
            }
        }
    }
}

// Utility CSS
.max-width-container {
    max-width: 360px;
}