#livestream-desktop-left-section-subcomponent {
    width: calc(100% / 3);
    max-width: 450px;
    display: flex;
    flex-direction: column;

    .lobby-container {
        // height: calc(50% - 20px - 2px);
        margin: 10px;
        border: 1px solid #dee2e6;
        border-radius: 15px 15px 0 0;
        transition: height 0.5s ease-in-out;

        .lobby-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .lobby-label {
                padding: 10px 0 10px 20px;
                font: 600 1.1em Lato;
            }

            .lobby-icon-container {
                padding: 0 10px;
                cursor: pointer;
            }
        }
    }

    .product-container {
        // height: calc(50% - 10px - 2px);
        margin: 0 10px 10px 10px;
        border: 1px solid #dee2e6;
        border-radius: 15px;
        transition: height 0.5s ease-in-out;

        .gifts-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .gifts-label {
                padding: 10px 0 10px 20px;
                font: 600 1.1em Lato;
            }

            .gifts-icon-container {
                padding: 0 10px;
                cursor: pointer;
            }
        }
    }

    // Utility CSS
    .lobby-minimize-height {
        height: calc(40% - 20px - 2px);
    }

    .lobby-maximize-height {
        height: calc(60% - 20px - 2px);
    }

    .gifts-minimize-height {
        height: calc(40% - 10px - 2px);
    }

    .gifts-maximize-height {
        height: calc(60% - 10px - 2px);
    }
}